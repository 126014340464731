import consola from 'consola'

type Log = typeof consola & { time: (label: string) => void, timeEnd: (label: string) => void, timeCapture: (label: string) => void, _timeStamps: { [key: string]: Date } }
const log = consola as Log

log._timeStamps = {}

log.time = function (name: string) {
  log._timeStamps[name] = new Date()
}

log.timeCapture = function (name: string) {
  if (log._timeStamps[name]) {
    const timeStamp = +new Date() - +log._timeStamps[name]
    const seconds = Math.floor(timeStamp / 1000)
    const minutes = Math.floor(seconds / 60)
    const hours = Math.floor(minutes / 60)
    let timeStr = name + ':'
    if (hours >= 1) {
      timeStr += ' ' + hours + 'h'
    }
    if (minutes >= 1) {
      timeStr += ' ' + minutes + 'm'
    }
    if (seconds >= 1) {
      timeStr += ' ' + seconds + 's'
    }
    if (timeStamp >= 1) {
      timeStr += ' ' + timeStamp % 1000 + 'ms'
    }
    log.trace(timeStr)
    return
  }
  log.trace(name + ' is not found')
}

log.timeEnd = function (name: string) {
  log.timeCapture(name)
  delete log._timeStamps[name]
}

export default log
