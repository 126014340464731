import log from 'consola'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('apollo:error', (error) => {
    log.error('apollo:error', error)
    if (process.client) {
      const { $sentryCaptureException, $sentrySetContext } = useNuxtApp()
      $sentrySetContext('apollo', { error })
      $sentryCaptureException(error)
    }
  })
})
