export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"title","content":"Submit Sighting | Enigma Labs"},{"name":"author","content":"Enigma Labs"},{"name":"publisher","content":"Enigma Labs"},{"name":"apple-mobile-web-app-title","content":"Submit Sighting"},{"property":"og:title","content":"Submit Sighting | Enigma Labs"},{"property":"og:description","content":"Submit your UAP (UFO) sighting with us here. Enigma is the largest queryable, scored UAP sighting database in the world."},{"property":"og:type","content":"website"},{"property":"og:locale","content":"en_US"},{"property":"og:url","content":"https://submit.enigmalabs.io"},{"property":"og:image","content":"https://submit.enigmalabs.io/submit-sighting-og.jpg"},{"name":"twitter:site","content":"@enigmalabs"},{"name":"twitter:creator","content":"@enigmalabs"},{"name":"twitter:title","content":"Submit Sighting | Enigma Labs"},{"name":"twitter:description","content":"Submit your UAP (UFO) sighting with us here. Enigma is the largest queryable, scored UAP sighting database in the world."},{"name":"twitter:image","content":"https://submit.enigmalabs.io/submit-sighting-og.jpg"},{"hid":"description","name":"description","content":"Submit your UAP (UFO) sighting with us here. Enigma is the largest queryable, scored UAP sighting database in the world."},{"charset":"utf-8"}],"link":[{"rel":"icon","type":"image/png","href":"/favicon-32x32.png"},{"rel":"icon","type":"image/png","href":"/favicon-16x16.png"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"en"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"