import VueGtag from 'vue-gtag'
import log from 'consola'

export default defineNuxtPlugin(({ vueApp }) => {
  const env = useRuntimeConfig().public.environment
  const enabled = env === 'production'
  vueApp.use(VueGtag, {
    config: {
      id: 'G-5YSL623N57'
    },
    enabled
  })
  log.debug(`GA initialized, currently ${enabled ? 'enabled' : 'disabled'}`)
})
