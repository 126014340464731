import { default as _47builds_47web_47incident_45submission_45v2_47pages_47confirmation_47_91uid_93_46vueMeta } from "/builds/web/incident-submission-v2/pages/confirmation/[uid].vue?macro=true";
import { default as _47builds_47web_47incident_45submission_45v2_47pages_47index_46vueMeta } from "/builds/web/incident-submission-v2/pages/index.vue?macro=true";
export default [
  {
    name: _47builds_47web_47incident_45submission_45v2_47pages_47confirmation_47_91uid_93_46vueMeta?.name ?? "confirmation-uid",
    path: _47builds_47web_47incident_45submission_45v2_47pages_47confirmation_47_91uid_93_46vueMeta?.path ?? "/confirmation/:uid",
    file: "/builds/web/incident-submission-v2/pages/confirmation/[uid].vue",
    children: [],
    meta: _47builds_47web_47incident_45submission_45v2_47pages_47confirmation_47_91uid_93_46vueMeta,
    alias: _47builds_47web_47incident_45submission_45v2_47pages_47confirmation_47_91uid_93_46vueMeta?.alias || [],
    redirect: _47builds_47web_47incident_45submission_45v2_47pages_47confirmation_47_91uid_93_46vueMeta?.redirect || undefined,
    component: () => import("/builds/web/incident-submission-v2/pages/confirmation/[uid].vue").then(m => m.default || m)
  },
  {
    name: _47builds_47web_47incident_45submission_45v2_47pages_47index_46vueMeta?.name ?? "index",
    path: _47builds_47web_47incident_45submission_45v2_47pages_47index_46vueMeta?.path ?? "/",
    file: "/builds/web/incident-submission-v2/pages/index.vue",
    children: [],
    meta: _47builds_47web_47incident_45submission_45v2_47pages_47index_46vueMeta,
    alias: _47builds_47web_47incident_45submission_45v2_47pages_47index_46vueMeta?.alias || [],
    redirect: _47builds_47web_47incident_45submission_45v2_47pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("/builds/web/incident-submission-v2/pages/index.vue").then(m => m.default || m)
  }
]