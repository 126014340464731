import { LogLevel } from 'consola'

export default defineNuxtPlugin(() => {
  const env = useRuntimeConfig().public.environment

  switch (env) {
    case 'production':
      log.level = LogLevel.Warn
      break
    case 'development':
      log.level = LogLevel.Trace
      break
    case 'staging':
      log.level = LogLevel.Debug
      break
  }

  return {
    provide: {
      log
    }
  }
})
