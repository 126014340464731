// plugins/sentry.client.ts
import { defineNuxtPlugin, useRuntimeConfig } from '#app'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import { CaptureContext, Attachment } from '@sentry/types'

export default defineNuxtPlugin(async ({ vueApp, $router }) => {
  const config = useRuntimeConfig()

  if (config.public.environment !== 'production' && config.public.environment !== 'staging') {
    log.debug(`Sentry disabled, environment is ${config.public.environment}`)
    return {
      provide: {
        sentrySetContext: (_n: string, _context: any) => { },
        sentrySetUser: (_user: Sentry.User) => { },
        sentrySetTag: (_tagName: string, _value: string | number | bigint | boolean | symbol | null | undefined) => { },
        sentryAddBreadcrumb: (_breadcrumb: Sentry.Breadcrumb) => { },
        sentryCaptureException: (_error: any, _context?: CaptureContext) => { },
        sentryAddAttachment: (_attachment: Attachment) => { }
      }
    }
  }

  Sentry.init({
    app: [vueApp],
    dsn: config.public.SENTRY_DSN,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.7,
    tracesSampleRate: config.SENTRY_TRACES_SAMPLE_RATE || 0.7, // Sentry recommends adjusting this value in production
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation($router),
        tracePropagationTargets: ['localhost', 'submit.enigmalabs.io', /^\//]
      }),
      new Sentry.Replay({ maskAllText: false, maskAllInputs: false })
    ],
    logErrors: false, // Note that this doesn't seem to work with nuxt 3
    debug: false,
    environment: config.public.environment,
    // The following enables exeptions to be logged to console despite logErrors being set to false (preventing them from being passed to the default Vue err handler)
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, log it.
      if (event.exception) {
        log.error(`[Exeption handled by Sentry]: (${hint.originalException})`, { event, hint })
      }
      // Continue sending to Sentry
      return event
    }
  })

  vueApp.mixin(Sentry.createTracingMixins({ trackComponents: true, timeout: 2000, hooks: ['activate', 'mount', 'update'] }))
  Sentry.attachErrorHandler(vueApp, { logErrors: false, attachProps: true, trackComponents: true, timeout: 2000, hooks: ['activate', 'mount', 'update'] })

  log.info('Sentry enabled')

  return {
    provide: {
      sentrySetContext: (n: string, context: any) => Sentry.setContext(n, context),
      sentrySetUser: (user: Sentry.User) => Sentry.setUser(user),
      sentrySetTag: (tagName: string, value: string | number | bigint | boolean | symbol | null | undefined) => Sentry.setTag(tagName, value),
      sentryAddBreadcrumb: (breadcrumb: Sentry.Breadcrumb) => Sentry.addBreadcrumb(breadcrumb),
      sentryCaptureException: (error: any, context?: CaptureContext) => Sentry.captureException(error, context),
      sentryAddAttachment: (attachment: Attachment) => Sentry.configureScope(s => s.addAttachment(attachment))
    }
  }
})
